@import "constants.css";

.historyItemTitle {
    font-size: var(--HistoryItemTitleFontSize);
    font-family: Muli-Bold, sans-serif;
}

.historyItemText {
    font-size: var(--MidFontSize);
    font-family: Muli-Light, sans-serif;
    line-height: 1.6;
    margin-top: 20px;
}

@keyframes downToUpDisplayAnimationHistory {
    from {
        transform: translateY(1000px);
    }
    to {
        transform: translateY(0);
    }
}

.downToUpDisplayAnim1History {
    transform: translateY(1000px);
    animation-name: downToUpDisplayAnimationHistory;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.18, 0.92, 0.55, 1.01);
}

@keyframes downToUpDisplayAnimationHistory2 {
    from {
        transform: translateY(70px);
    }
    to {
        transform: translateY(0);
    }
}

.downToUpDisplayAnim1History2 {
    transform: translateY(70px);
    animation-name: downToUpDisplayAnimationHistory2;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

.downToUpDisplayAnim1History3 {
    transform: translateY(1000px);
    animation-name: downToUpDisplayAnimationHistory;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.18, 0.92, 0.55, 1.01);
    animation-delay: 0.2s;
}