@keyframes RobotAnimation {
    0%   { scale: 1 }
    50%  { scale: 1.03 }
    100% { scale: 1 }
}

.infiniteScaleBounceAnim {
    animation-name: RobotAnimation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}