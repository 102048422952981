@import "constants.css";

.button {
    background: #2B2D2F;
    height: 80px;
    width: 200px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 4px;
}

.text {
    font: bold 1.25rem/1 poppins;
    color: #71DFBE;
    position: absolute;
    top: 50%;
    transform: translateY(-52%);
    left: 0;
    right: 0;
}

.progress-bar {
    position: absolute;
    height: 10px;
    width: 0;
    right: 0;
    top: 50%;
    left: 50%;
    border-radius: 200px;
    transform: translateY(-50%) translateX(-50%);
    background: lighten(#2B2D2F, 15%);
}

.svgSubmit {
    width: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    right: 0;
}

.check {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
}