:root {
    --White: #FFFFFF;
    --Black: #000000;
    --Green: #01ac10;
    --LightGreen: #ebf1ef;
    --DarkGreen: #009012;;
    --Grey: #A3A6AE;
    --LightGrey: #EBEBEB;
    --DarkGrey: #474D5E;
    --HeaderSelection: #FBFBFB;
    --InputGrey: rgba(0, 0, 0, 0.04);
    --InputDarkGrey: rgba(0, 0, 0, 0.6);
    --Background: #F5F5F7;
    --FooterGrey: #d3d2d2;
    --DarkBlue: #00346f;
    --LightBlue: #61A9D3;

    --Radius: 50px;
    --MapRadius: 15px;
    --ServicesRadius: 20px;
    --ServicesRadius2: 0 0 20px 20px;
    --HeaderBackgroundColor: var(--White);
    --BackgroundColor: var(--Background);

    --GiantFontSize: clamp(35px, 6vw, 110px);
    --PageTitleFontSize: clamp(26px, 3vw, 50px);
    --TitleFontSize: clamp(18px, 1.5vw, 30px);
    --BiggerFontSize: clamp(18px, 1.25vw, 24px);
    --BigFontSize: clamp(14px, 1vw, 20px);
    --MidFontSize: clamp(12px, 1vw, 16px);
    --SmallFontSize: clamp(10px, 1vw, 14px);

    --CompanyDescriptionFontSize: clamp(14px, 1.5vw, 26px);

    --HistoryItemTitleFontSize: clamp(20px, 3vw, 52px);

    --HeaderHeight: 85px;
}

.goto {
    text-underline-offset: 4px;
}

.goto:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--Green);
    transition: 0.2s;
    text-underline-offset: 4px;
}

.gotoWhite {
    text-underline-offset: 4px;
}

.gotoWhite:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--White);
    transition: 0.2s;
    text-underline-offset: 4px;
}

.sectionTitle {
    font-family: Muli-Bold, sans-serif;
    font-size: var(--TitleFontSize);
    color: var(--Black);
    margin-bottom: 40px;
    font-weight: bold;
    opacity: 0;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.10);
}

.sectionTitleWhite {
    font-family: Muli-Bold, sans-serif;
    font-size: var(--TitleFontSize);
    color: var(--White);
    margin-bottom: 40px;
    font-weight: bold;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.10);
}

.sectionTitleGreen {
    font-family: Muli-Bold, sans-serif;
    font-size: var(--TitleFontSize);
    color: var(--Green);
    margin-bottom: 40px;
    font-weight: bold;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.10);
}

@keyframes opacityDisplayAnimation {
    from {
        opacity: 0;
        scale: 0.7;
    }
    to {
        opacity: 100;
        scale: 1;
    }
}

.opacityDisplayAnim1 {
    opacity: 0;
    animation-name: opacityDisplayAnimation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.opacityDisplayAnim2 {
    opacity: 0;
    animation-name: opacityDisplayAnimation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.2s;
}

.opacityDisplayAnim3 {
    opacity: 0;
    animation-name: opacityDisplayAnimation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.4s;
}

.opacityDisplayAnim4 {
    opacity: 0;
    animation-name: opacityDisplayAnimation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.6s;
}

.opacityDisplayAnim5 {
    opacity: 0;
    animation-name: opacityDisplayAnimation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.8s;
}

.opacityDisplayAnim6 {
    opacity: 0;
    animation-name: opacityDisplayAnimation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1s;
}

.opacityDisplayAnim7 {
    opacity: 0;
    animation-name: opacityDisplayAnimation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.2s;
}

.opacityDisplayAnim8 {
    opacity: 0;
    animation-name: opacityDisplayAnimation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.4s;
}

@keyframes bounceDisplayAnimation {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0;
        margin-top: -45px;
    }
    30% {
        opacity: 0.25;
        margin-top: 0;
    }
    45% {
        opacity: 0.75;
        margin-top: -30px;
    }
    60% {
        opacity: 1;
        margin-top: 0;
    }
    75% {
        opacity: 1;
        margin-top: -15px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes fadeDisplayAnimation {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }
    to {
        opacity: 100;
        transform: translateY(0);
    }
}

.fadeDisplayAnim1 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.fadeDisplayAnim2 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.2s;
}

.fadeDisplayAnim3 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.4s;
}

.fadeDisplayAnim4 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.6s;
}

.fadeDisplayAnim5 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.8s;
}

.fadeDisplayAnim6 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1s;
}

.fadeDisplayAnim7 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.2s;
}

.fadeDisplayAnim8 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.4s;
}

.fadeDisplayAnim9 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.6s;
}

.fadeDisplayAnim10 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.8s;
}

.fadeDisplayAnim11 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 2s;
}

.fadeDisplayAnim12 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 2.2s;
}

.fadeDisplayAnim13 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 2.4s;
}

.fadeDisplayAnim14 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 2.6s;
}

.fadeDisplayAnim15 {
    opacity: 0;
    animation-name: fadeDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 2.8s;
}

@keyframes leftToRightDisplayAnimation {
    from {
        opacity: 0;
        scale: 0.7;
        transform: translateX(-30px);
    }
    to {
        opacity: 100;
        scale: 1;
        transform: translateX(0);
    }
}

.leftToRightDisplayAnim1 {
    opacity: 0;
    animation-name: leftToRightDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.leftToRightDisplayAnim2 {
    opacity: 0;
    animation-name: leftToRightDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.2s;
}

.leftToRightDisplayAnim3 {
    opacity: 0;
    animation-name: leftToRightDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.4s;
}

.leftToRightDisplayAnim4 {
    opacity: 0;
    animation-name: leftToRightDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.6s;
}

.leftToRightDisplayAnim5 {
    opacity: 0;
    animation-name: leftToRightDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.8s;
}

.leftToRightDisplayAnim6 {
    opacity: 0;
    animation-name: leftToRightDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1s;
}

.leftToRightDisplayAnim7 {
    opacity: 0;
    animation-name: leftToRightDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.2s;
}

.leftToRightDisplayAnim8 {
    opacity: 0;
    animation-name: leftToRightDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.4s;
}

@keyframes rightToLeftDisplayAnimation {
    from {
        opacity: 0;
        scale: 0.7;
        transform: translateX(30px);
    }
    to {
        opacity: 100;
        scale: 1;
        transform: translateX(0);
    }
}

.rightToLeftDisplayAnim1 {
    opacity: 0;
    animation-name: rightToLeftDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.rightToLeftDisplayAnim2 {
    opacity: 0;
    animation-name: rightToLeftDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.2s;
}

.rightToLeftDisplayAnim3 {
    opacity: 0;
    animation-name: rightToLeftDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.4s;
}

.rightToLeftDisplayAnim4 {
    opacity: 0;
    animation-name: rightToLeftDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.6s;
}

.rightToLeftDisplayAnim5 {
    opacity: 0;
    animation-name: rightToLeftDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.8s;
}

.rightToLeftDisplayAnim6 {
    opacity: 0;
    animation-name: rightToLeftDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1s;
}

.rightToLeftDisplayAnim7 {
    opacity: 0;
    animation-name: rightToLeftDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.2s;
}

.rightToLeftDisplayAnim8 {
    opacity: 0;
    animation-name: rightToLeftDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 1.4s;
}

@keyframes downToUpDisplayAnimation {
    from {
        opacity: 0;
        scale: 0.7;
        transform: translateY(30px);
    }
    to {
        opacity: 100;
        scale: 1;
        transform: translateY(0);
    }
}

.downToUpDisplayAnim1 {
    opacity: 0;
    animation-name: downToUpDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.downToUpDisplayAnim2 {
    opacity: 0;
    animation-name: downToUpDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.2s;
}

.downToUpDisplayAnim3 {
    opacity: 0;
    animation-name: downToUpDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.4s;
}

.downToUpDisplayAnim4 {
    opacity: 0;
    animation-name: downToUpDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.6s;
}

@keyframes infiniteFloatingBounceAnimation {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

.infiniteFloatingBounceAnim {
    animation-name: infiniteFloatingBounceAnimation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes infiniteScaleAnimation {
    0%   { scale: 1; }
    50%  { scale: 1.1; }
    100% { scale: 1; }
}

.infiniteScaleAnim {
    animation-name: infiniteScaleAnimation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.noOpacity {
    opacity: 0;
}