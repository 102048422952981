@import "./assets/css/constants.css";

@font-face {
  font-family: Muli-ExtraLight;
  src: url('./assets/fonts/muli/Muli-ExtraLight.ttf');
  font-display: swap;
}

@font-face {
  font-family: Muli-Light;
  src: url('./assets/fonts/muli/Muli-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: Muli-Regular;
  src: url('./assets/fonts/muli/Muli.ttf');
  font-display: swap;
}

@font-face {
  font-family: Muli-SemiBold;
  src: url('./assets/fonts/muli/Muli-SemiBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: Muli-Bold;
  src: url('./assets/fonts/muli/Muli-Bold.ttf');
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: Muli-Regular, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--BackgroundColor);
  scroll-behavior: smooth;
}

code {
  font-family: Muli-Regular, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
