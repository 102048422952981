@import "./constants.css";

@media screen and (max-width: 1250px) {
    .backgroundPictures {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100%;
        overflow: hidden;
        background-attachment: scroll;
        width: 100%;
        background-position: center center, center center;
        background-repeat: no-repeat;
        transition: 0.2s ease;
    }
}

@media screen and (min-width: 1250px) {
    .backgroundPictures {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100%;
        overflow: hidden;
        background-attachment: fixed;
        width: 100%;
        background-position: center center, center center;
        background-repeat: no-repeat;
        transition: 0.2s ease;
    }
}

@keyframes notepadDisplayAnimation {
    from {
        opacity: 0;
        transform: translateX(75%);
    }
    to {
        opacity: 100;
        transform: translateX(0);
    }
}

.notepadDisplayAnim {
    animation-name: notepadDisplayAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

@keyframes mapDisplayAnimation {
    from {
        opacity: 0;
        scale: 0.2;;
    }
    to {
        opacity: 100;
        scale: 1;
    }
}

.mapDisplayAnim {
    animation-name: mapDisplayAnimation;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.videoHoverBox {
    background: var(--White);
    border-radius: var(--ServicesRadius);
    display: flex;
    margin: 15px 15px;
    flex-direction: column;
    box-shadow: 0 clamp(10px, 5vw, 20px) 35px clamp(-4px, 10vw, -20px) rgba(0, 0, 0, 0.2), 0 -1px 2px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    transition: 0.2s ease;
    top: 0;
}

.videoHoverBox:hover {
    box-shadow: 0 clamp(5px, 5vw, 10px) 35px -8px rgba(0, 0, 0, 0.2), 0 -1px 2px 0 var(--Green);
    top: -10px;
    cursor: pointer;
    color: var(--Green);
    transition: all cubic-bezier(0.18, 0.82, 0.85, 1.01) 0.5s;
}


@media screen and (min-width: 850px) {
    .qualitiesContainer{
        display: grid;
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        grid-gap: 1rem;
    }
}

@media screen and (max-width: 850px) {
    .qualitiesContainer{
        display: grid;
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        grid-gap: 1rem;
    }
}

.qualities {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    width: 15%;
    opacity: 0;
}

.qualities:hover {
    top: -3px;
    transition: all cubic-bezier(0.18, 0.82, 0.85, 1.01) 0.5s;
}

.doctolibLogoo {
    rotate: -7deg;
    transition: 0.3s ease;
}

.doctolibLogoo:hover {
    top: -4px;
}

.notepadImage {
    scale: 1;
    transition: 0.5s ease;
}

.notepadImage:hover {
    scale: 1.01;
    transition: 0.2s ease;
}