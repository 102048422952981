@media screen and (min-width: 550px) {
    .qualitiesContainer2{
        display: grid;
        grid-template-columns: repeat(3, minmax(150px, 1fr));
        grid-gap: 1rem;
    }
}

@media screen and (max-width: 550px) {
    .qualitiesContainer2{
        display: grid;
        grid-template-columns: repeat(1, minmax(150px, 1fr));
        grid-gap: 1rem;
    }
}