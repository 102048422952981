@import "constants.css";

.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 30px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery .pics:hover {
    filter: opacity(.8);
}

@media (max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 480px) {
    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
        padding: 0 12px;
    }
}

.fullscreen {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    transition: opacity .4s ease, visibility .4s ease, transfrom .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 990;
}

.fullscreen.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.fullscreen img {
    width: auto;
    max-width: 80%;
    height: auto;
    max-height: 80%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    margin: 0 auto;
}

.fullscreen.open .close {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 2.2rem;
    height: 2.2rem;
    padding: 5px;
    background: black;
    color: red;
    cursor: pointer;
    transition: 0.2s ease;
    border-radius: 50px;
    z-index: 998;
}

.fullscreen.open .close:hover {
    scale: 1.2;
}

.fullscreen.open .left {
    position: fixed;
    left: 20px;
    width: 2.2rem;
    height: 2.2rem;
    top: 50vh;
    padding: 5px;
    background: black;
    color: white;
    cursor: pointer;
    transition: 0.2s ease;
    border-radius: 50px;
    z-index: 998;
}

.fullscreen.open .left:hover {
    color: var(--Green);
    scale: 1.2;
}

.fullscreen.open .right {
    position: fixed;
    right: 20px;
    width: 2.2rem;
    height: 2.2rem;
    top: 50vh;
    padding: 5px;
    background: black;
    color: white;
    cursor: pointer;
    transition: 0.2s ease;
    border-radius: 50px;
    z-index: 998;
}

.fullscreen.open .right:hover {
    color: var(--Green);
    scale: 1.2;
}