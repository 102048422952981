@import "./constants.css";

@media screen and (max-width: 1250px) {
    .header {
        background: var(--HeaderBackgroundColor);
        height: var(--HeaderHeight);
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.2s ease;
        z-index: 1;
    }

    .headerShadow {
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
    }

    .burgerMenu {
        transition: 0.2s ease;
        margin-top: -40px;
        outline: none;
        padding-left: 60px;
        width: 300px;
        padding-top: 100px;
        cursor: pointer;
    }

    .wollenLogo {
        margin-left: 20px;
        height: 56px;
        cursor: pointer;
        transition: 0.2s ease;
    }

    .doctolibLogo {
        position: relative;
        height: 30px;
        cursor: pointer;
        transition: 0.5s ease;
        opacity: 0;
        animation-name: bounceDisplayAnimation;
        animation-duration: 1.8s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }

    .doctolibLogoMinScreenSize {
        height: 30px;
        cursor: pointer;
        transition: 0.5s ease;
    }

    .doctolibLogo:hover {
        margin-bottom: 3px;
    }

    .itemBox {
        cursor: pointer;
        height: auto;
        margin-top: 15px;
        display: flex;
        transition: 0.2s ease;
        position: relative;
    }

    .itemBox:hover {
        color: var(--Green);
    }

    .item {
        font-size: var(--BigFontSize);
        padding: 10px 2px 10px 2px;
    }

    .contact {
        background: var(--Green);
        color: var(--White);
        border-radius: var(--Radius);
        padding: 1px 25px 1px 25px;
        cursor: pointer;
        border: solid 2px var(--Green);
        transition: 0.2s ease;
        font-size: var(--BigFontSize);
        margin-top: 10px;
    }

    .contact:hover {
        background: var(--White);
        color: var(--Green);
    }

    .carretDownLogo {
        height: 10px;
    }

    .spaceLine {
        rotate: 90deg;
        margin-left: auto;
    }

    .searchBox {
        width: 100%;
    }

    .menuButton {
        position: absolute;
        right: 35px;
        top: 24px;
        padding-right: 10px;
    }

    .menuButtonInBurgerMenu {
        position: relative;
        left: -20px;
        top: -38px;
        padding-right: 10px;
    }

    .burgerMenuIcon {
        position: absolute;
        right: 20px;
        top: 29px;
    }

    .search {
        background: var(--White);
        border-radius: var(--Radius);
        height: auto;
        padding: 4px 20px 4px 20px;
        border: solid 2px var(--LightGrey);
        transition: 0.2s ease;
        outline: none;
        font-size: var(--MidFontSize);
        width: 150px;
        margin-left: -30px;
    }

    .searchBoxButtonImages {
        display: flex;
        align-items: center;
        margin-right: 20px;
        transition: 0.2s ease;
    }

    .buttonImageSearch {
        position: relative;
        left: -8px;
        top: 1px;
        height: 10px;
        cursor: pointer;
        transition: 0.2s ease;
        color: var(--Grey);
    }

    .buttonImageSearch:hover {
        color: var(--Green);
    }

    .buttonImageCancel {
        position: relative;
        left: -22px;
        top: 1px;
        height: 10px;
        cursor: pointer;
        transition: 0.2s ease;
        color: var(--Grey);
    }

    .buttonImageCancel:hover {
        color: red;
    }

    a {
        color: var(--Black);
        text-decoration: none;
    }

    .dropdown {
        position: absolute;
        width: 185px;
        border-radius: 5px;
        transform: translateY(30px);
        font-size: var(--MidFontSize);
        max-width: 190px;
        transition: height 1s ease;
        overflow: hidden;
    }

    .menu-item {
        display: flex;
        align-items: center;
        transition: 0.2s ease;
        padding: 10px 15px 10px 15px;
    }

    .menu-item:hover {
        color: var(--Green);
    }
}

@media screen and (min-width: 1250px){
    .header {
        background: var(--HeaderBackgroundColor);
        height: var(--HeaderHeight);
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.2s ease;
        z-index: 1;
    }

    .headerShadow {
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    }

    .wollenLogo {
        margin-left: 20px;
        height: 56px;
        cursor: pointer;
        transition: 0.2s ease;
    }

    .doctolibLogo {
        height: 30px;
        cursor: pointer;
        transition: 0.2s ease;
        opacity: 0;
        animation-name: bounceDisplayAnimation;
        animation-duration: 1.8s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }

    .doctolibLogo:hover {
        margin-bottom: 3px;
    }

    .itemBox {
        cursor: pointer;
        height: auto;
    }

    .itemBox:hover {
        color: var(--Green);
    }

    .item {
        font-size: var(--BigFontSize);
        padding: 0 8px 0 8px;
        white-space: nowrap;
    }

    .contact {
        background: var(--Green);
        color: var(--White);
        border-radius: var(--Radius);
        height: auto;
        padding: 1px 20px 1px 20px;
        cursor: pointer;
        border: solid 2px var(--Green);
        transition: 0.2s ease;
        font-size: var(--BigFontSize);
    }

    .contact:hover {;
        background: var(--White);
        color: var(--Green);
    }

    .searchBox {
        display: flex;
        align-items: center;
        transition: 0.2s ease;
        margin-left: 15px;
    }

    .search {
        background: var(--White);
        border-radius: var(--Radius);
        height: auto;
        margin-right: 15px;
        padding: 4px 20px 4px 20px;
        border: solid 2px var(--LightGrey);
        transition: 0.2s ease;
        outline: none;
        font-size: var(--MidFontSize);
    }

    .buttonImageSearch {
        position: absolute;
        height: 10px;
        left: 8px;
        cursor: pointer;
        color: var(--Grey);
    }

    .buttonImageSearch:hover {
        color: var(--Green);
    }

    .buttonImageCancel {
        color: var(--Grey);
        position: absolute;
        right: 22px;
        height: 10px;
        cursor: pointer;
        transition: 0.2s ease;
    }

    .buttonImageCancel:hover {
        color: red;
    }

    .spaceLine {
        height: 15px;
        transition: 0.2s ease;
    }

    .carretDownLogo {
        margin-left: -8px;
        margin-right: 3px;
        height: 8px;
    }

    a {
        color: var(--Black);
        text-decoration: none;;
    }

    .dropdown {
        position: absolute;
        top: 90px;
        width: auto;
        background-color: var(--White);
        border-radius: 5px;
        transition: 0.2s ease;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        font-size: var(--MidFontSize);
        overflow: hidden;
    }

    .menu-item {
        display: flex;
        align-items: center;
        transition: 0.2s ease;
        padding: 10px 15px 10px 15px;
        white-space: nowrap;
    }

    .menu-item:hover {
        color: var(--Green);
        background-color: var(--BackgroundColor);
    }
}

.languageFlagsBox {
    position: fixed;
    align-items: start;
    transition: 0.2s ease;
    z-index: 2;
    top: 100px;
}

@keyframes flagsAnimation {
    from {
        opacity: 0;
        transform: translatex(-100%);
    }
    to {
        opacity: 100;
        transform: translatex(0%);
    }
}

@keyframes flagsAnimation2 {
    from {
        opacity: 0;
        transform: translatex(100%);
    }
    to {
        opacity: 100;
        transform: translatex(0%);
    }
}

.languageFlag {
    height: 20px;
    position: absolute;
    cursor: pointer;
    transition: 0.2s ease;
    animation-name: flagsAnimation;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    transform: translatex(-700%);
}

.languageFlag:hover {
    height: 22px;
}

.languageFlag2 {
    height: 30px;
    position: absolute;
    cursor: pointer;
    transition: 0.2s ease;
    animation-name: flagsAnimation2;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    transform: translatex(700%);
}

.languageFlag2:hover {
    height: 32px;
}

@keyframes overlayAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 60%;
    }
}

.overlay {
    background: var(--White);
    position: fixed;
    top: 0;
    transition: 0.5s ease;
    animation-name: overlayAnimation;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.18, 0.82, 0.85, 1.01);
}

.topSpaceBox {
    height: var(--HeaderHeight);
    width: 100%;
}

.buttonImageCancelInfos {
    position: relative;
    right: 40px;
    top: 40px;
    cursor: pointer;
    transition: 0.2s ease;
    color: var(--Black);
}

.buttonImageCancelInfos:hover {
    color: red;
}

@keyframes availableInfosAnimation {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 100%;
    }
    75% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
    }
}

.availableInfosAnim {
    animation-name: availableInfosAnimation;
    animation-duration: 5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 2.2s;
}